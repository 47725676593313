import _padding from "./padding";
import _margin from "./margin";
var exports = {};
const padding = _padding;
const margin = _margin;

exports = function (opts) {
  padding(opts);
  margin(opts);
};

export default exports;