import _lodash from "lodash.flatmap";
var exports = {};
const flatMap = _lodash;

exports = function ({
  addUtilities,
  theme,
  variants
}) {
  const generators = [(size, modifier) => ({
    [`[dir=rtl] .pr-${modifier}`]: {
      "padding-left": `${size}`,
      "padding-right": "unset"
    },
    [`[dir=rtl] .pl-${modifier}`]: {
      "padding-left": "unset",
      "padding-right": `${size}`
    }
  })];
  const utilities = flatMap(generators, generator => {
    return flatMap(theme("padding"), generator);
  });
  addUtilities(utilities, variants("padding"));
};

export default exports;